import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class Login extends Component {
    //https://github.com/facebook/create-react-app#creating-an-app
  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handlePwChange = this.handlePwChange.bind(this);
  }

  componentDidMount() {
    fetch("/api/time")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items
          });
          console.log(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  handleSubmit(event){
    var body = "username="+this.state.username+"&password="+this.state.password;

    fetch("/api/user/auth", {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
      body: body
    })
      .then( res => res.json() )
      .then(
        (result) => {
          console.log(result);
          if( result !== null && result['auth'] !== null ){
            var cookies = new Cookies();
            cookies.set('notation_auth', result['auth'], { path: '/' });
            this.props.after(result['auth']);
          }
        },
        (error) => {
          console.log("error : "+error);
        }
      );
      
    console.log("submit button pressed, "+this.state);
    event.preventDefault();
  }

  handleUserChange(event) {
    this.setState({username: event.target.value});
  }

  handlePwChange(event) {
    this.setState({password: event.target.value});
  }

  render() {
    return (
      <div className="text-center">
        <form className="form-signin" onSubmit={this.handleSubmit}>
          <label>Username:</label>
          <input className="form-control" type="text" value={this.state.username} onChange={this.handleUserChange} required autoFocus/>
          <label>Password:</label>
          <input className="form-control" type="password" value={this.state.password} onChange={this.handlePwChange} required/>
          <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        </form>
      </div>
    );
  }
};

export default Login;
