import React, { Component } from 'react';

import Note from './Note';
import CreateNote from './CreateNote';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import './Notes.css';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.setState({'notes': []});
    this.postCallback = this.postCallback.bind(this);
  }

  postCallback(post) {
    var newNote = {'id': post.notation,
      'created_at': post.time,
      'updated_at': post.time,
      'tags': post.tags,
      'category': post.category,
      'file_id': post.file_id,
      'file_path': post.file_path,
      'comment': post.text
    };

    this.state.notes.unshift(newNote);
    this.setState({'notes': this.state.notes});
  }


  componentDidMount() {
    fetch("/api/notations/list?auth="+this.props.auth)
      .then( res => res.json() )
      .then(
        (result) => {
          if( result.notations !== undefined && result.notations !== null ){
            this.setState({'notes': result.notations});
          }
        },
        (error) => {

        }
      );
  }


  render() {
    var toReturn = (<div >Notes go here 0</div>);

    if(this.state !== null && this.state.notes !== null){
      var notes = this.state.notes.map((item,i) =>
        <li key={i}>
          <Note
            id={item.id}
            comment={item.comment}
            created_at={item.created_at}
            tags={item.tags}
            category={item.category}
            file_id={item.file_id}
            file_path={item.file_path}
            href={item.href}
            title={item.title}
            auth={this.props.auth}
          />
        </li>
      );
      // TODO: tabs for note creation types
      toReturn = (
        <div className="everything row col-12">
        <CreateNote auth={this.props.auth} postCallback={this.postCallback}/>
        <div className="Notes col-12 text-left">
          <div className="row total">
            notes available: {this.state.notes.length}
          </div>
          <div className="row">
          <ul className="list-unstyled col-12">{notes}</ul>
          </div>
        </div>
        </div>
      );
    }
    return toReturn;
  }



};

export default Notes;
