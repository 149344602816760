import React, { Component } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import CreatableSelect from 'react-select/lib/Creatable';
// https://react-select.com/home

import './CreateNote.css';

class CreateNote extends Component {
  constructor(props) {
    super(props);
    this.setState({
      'current_note': "",
      'files': [],
      'posting': false,
      'tags': [],
      'current_tags': "testing,",
      'all_tags': []
    });
    this.postCallback = this.props.postCallback;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
  }


  componentDidMount() {
    fetch("/api/tags/list?auth="+this.props.auth)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            all_tags: result.tags.map( function(b){
              return {'label': b.tag, 'value': b.tag }
            })
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      );
  }

  handleChange(event){
    this.setState({'current_note': event.target.value});
  }

  onDrop(files){
    //do something with files
    console.group('File test');
    console.log(files);
    console.groupEnd();
    this.setState({files});
  }

  onCancel(){
    this.setState({
      files: []
    });
  }

  
  handleTagChange = (newValue: any, actionMeta: any) => {
    //console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    //console.groupEnd();
    var tagList = "";
    newValue.forEach( function(element){
      tagList += element.label + ",";
    });
    this.setState({'current_tags': tagList});

  };

  handleSubmit(event){

    var debug = true;
    var current = this.state.current_note;
    var currentTags = this.state.current_tags;
    var tags = [];

    if( currentTags === undefined ){
      currentTags = "testing";
      tags = ['testing'];
    }

    if(this.state.current_tags !== null && this.state.current_tags !== undefined)
      tags = currentTags.split(",");

    var data = new FormData();
    data.append('comment', current);
    data.append('auth', this.props.auth);
    data.append('tags', currentTags);

    if( this.state && this.state.files && this.state.files.length > 0 )
      data.append('file', this.state.files[0], this.state.files[0].name);

    if(debug){
      console.group("upload file");
      console.log(data);
      console.groupEnd();
    }

    if(current.length > 1 && !this.state.posting){
      this.setState({'posting': true});
      fetch("/api/notation/create", {
        method: 'POST',
        headers: new Headers({
          'auth': this.props.auth,
        }),
        body: data
      })
        .then( res => res.json() )
        .then(
          (result) => {
            result['comment'] = current;
            result['tags'] = tags;
            this.setState({'current_note': ''});
            this.postCallback(result);
            this.setState({'posting': false});
          }
        );
    }

    event.preventDefault();
  }
  
  render() {

    var currentNote = "";
    var all_tags = [];

    if(
      this.state !== undefined &&
      this.state !== null &&
      this.state.current_note !== undefined &&
      this.state.current_note !== null
    )
      currentNote = this.state.current_note;

    if(
      this.state !== undefined &&
      this.state !== null &&
      this.state.all_tags !== undefined &&
      this.state.all_tags !== null
    )
      all_tags = this.state.all_tags;
    
    var f1 = [];
    if( this.state && this.state.files)
      f1 = this.state.files;

    var files = f1.map( file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return(
      <div className="CreateNote col-sm-12 text-left">
      <form className="col-sm-12" onSubmit={this.handleSubmit}>
      <div className="row">
        <textarea value={currentNote} onChange={this.handleChange} className="col-sm-12 newnote">
        </textarea>
      </div>
      <div className="row">
        <Dropzone
          onDrop={this.onDrop.bind(this)}
          onFileDialogCancel={this.onCancel.bind(this)}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drop files here, or click to select files</p>
            </div>
          )}
        </Dropzone>
        <aside>
          <ul>{files}</ul>
        </aside>
      </div>
      <div className="row">
        <input className="col-2" type="submit" value="Post"/>
        <div className="col-1"/>
        <CreatableSelect 
          name="tags"
          isMulti 
          onChange={this.handleTagChange}
          className="col-sm-9 tagentry" 
          options={all_tags} />
      </div>
      </form>
      </div>
    );
  }



};

export default CreateNote;

