import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import Frontpage from './Frontpage';
import Bookmarklet from './Bookmarklet';
import NoteDetail from './NoteDetail';


class App extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  };

  render() {

    return (
      <Router>
        <Switch>
          <Route path="/bookmark">
            <Bookmarklet />
          </Route>
          <Route path="/note">
            <NoteDetail />
          </Route>
          <Route exact path="/">
            <Frontpage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
