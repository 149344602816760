import React, { Component } from 'react';
//import CreatableSelect from 'react-select/lib/Creatable';
// https://react-select.com/home

// https://betterexplained.com/articles/how-to-make-a-bookmarklet-for-your-web-application/
import './NoteDetail.css';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import {
  useLocation
} from "react-router-dom";

class NoteDetail extends Component {
  constructor(props) {
    super(props);
    this.handleURLChange = this.handleURLChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {'auth': null, 'logged_in': false, 'url': '','title': '', 'tags': '', 'comment': ''};
  }

  componentDidMount() {
    let values = queryString.parse(window.location.search);
    var url = '';
    if(values.url !== undefined && values.url !== null){
      url = values.url;
    }
    var title = '';
    if(values.title !== undefined && values.title !== null){
      title = values.title;
    }
    var cookies = new Cookies();
    var auth = cookies.get('notation_auth')
    if( auth !== null && auth !== undefined && auth.length > 0){
      this.setState({
        'auth': cookies.get('notation_auth'),
        'logged_in': true,
        'url': values.url
      });
    }else{
      console.log("should redirect");
    }
  }

  handleURLChange(event) {
    this.setState({'url': event.target.value});
  }

  handleTitleChange(event) {
    this.setState({'title': event.target.value});
  }

  handleCommentChange(event) {
    this.setState({'comment': event.target.value});
  }

  handleTagsChange(event) {
    this.setState({'tags': event.target.value});
  }


  handleSubmit(event) {
    var body = "auth="+this.state.auth+"&url="+this.state.url+"&title="+this.state.title+"&tags="+this.state.tags+"&comment="+this.state.comment;
    fetch("/api/bookmark/create", {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
      body: body
    })
      .then( res => res.json() )
      .then(
        (result) => {
          console.log(result);
          if( result !== null ){
            event.preventDefault();
            window.opener = null;
            window.open('', '_self');
            window.close();
          }
        },
        (error) => {
          console.log("error : "+error);
        }
      );
  }

  render() {
    var loggedIn = "not logged in";
    return (
      <div className="NoteDetail container">
        {loggedIn}
      </div>
    );
  }

};

export default NoteDetail;
