import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from './logo.svg';
import Login from './Login';
import Bookmarklet from './Bookmarklet';
import CreateAccount from './CreateAccount';
import Logout from './Logout';
import Notes from './Notes';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import './App.css';


class Frontpage extends Component {

  constructor(props) {
    super(props);
    this.state = {'auth': null, 'logged_in': false};
    this.afterLogin = this.afterLogin.bind(this);
    this.afterLogout = this.afterLogout.bind(this);
  }

  componentDidMount() {
    var cookies = new Cookies();
    var auth = cookies.get('notation_auth')
    if( auth !== null && auth !== undefined && auth.length > 0){
      this.setState({'auth': cookies.get('notation_auth'), 'logged_in': true});
    }
  };

  afterLogin(item) {
    this.setState({'auth':  item, 'logged_in': true});
  }

  afterLogout() {
    this.setState({'auth': null, 'logged_in': false});
  };

  render() {
    var login = (<Tabs>
      <TabList>
        <Tab>Login</Tab>
        <Tab>Create Account</Tab>
      </TabList>

      <TabPanel>
        <Login after={this.afterLogin}/>
      </TabPanel>
      <TabPanel>
        <CreateAccount after={this.afterLogin}/>
      </TabPanel>
    </Tabs>);

    if( this.state.logged_in ) login = (<div/>);

    var notes = <Notes auth={this.state.auth}/>;
    if( !this.state.logged_in ) notes = (<div/>);

    var logout = <Logout after={this.afterLogout}/>;
    if( !this.state.logged_in ) logout = (<div/>);

    //try container-fluid later
    return (
      <div className="App container">
        <header className="App-header">
          {login}
          {notes}
          {logout}
        </header>
      </div>
    );
  }
}

export default Frontpage;
