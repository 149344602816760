import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class Logout extends Component {
  constructor(props){
    super(props);
    this.deleteCookie = this.deleteCookie.bind(this);
  }

  deleteCookie(event){
    event.preventDefault();
    var cookies = new Cookies();
    cookies.remove('notation_auth');
    console.log("delete cookie");
    this.props.after();
  };

  render() {
    return (
      <form className="form-signin" onSubmit={this.deleteCookie}>
        <button type="submit" className="btn btn-lg btn-primary btn-block">Logout</button>
      </form>
    );
  }
};

export default Logout;
