import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class CreateAccount extends Component {

  constructor(props) {
    super(props);
    this.state = { username: '', password: '', password2: '', display: '' };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleDisplayChange = this.handleDisplayChange.bind(this);
    this.handlePwChange = this.handlePwChange.bind(this);
    this.handlePw2Change = this.handlePw2Change.bind(this);
  }

  handleUserChange(event) {
    this.setState({username: event.target.value});
  }

  handleDisplayChange(event) {
    this.setState({display: event.target.value});
  }


  handlePwChange(event) {
    this.setState({password: event.target.value});
  }

  handlePw2Change(event) {
    this.setState({password2: event.target.value});
  }


  handleSubmit(event){

    var body = "username="+this.state.username+"&password="+this.state.password+"&display="+this.state.display;

    fetch("/api/user/create", {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
      body: body
    })
      .then( res => res.json() )
      .then(
        (result) => {
          console.log(result);
          if( result !== null && result['auth'] !== null ){
            var cookies = new Cookies();
            cookies.set('notation_auth', result['auth'], { path: '/' });
            this.props.after(result['auth']);
          }
        },
        (error) => {
          console.log("error : "+error);
        }
      );
    event.preventDefault();
  }

  render() {
    return (
      <div class="text-center">
        <form class="form-signin" onSubmit={this.handleSubmit}>
          Username: <input class="form-control" type="text" value={this.state.username} onChange={this.handleUserChange} required autofocus/>
          Display Name: <input class="form-control" type="text" value={this.state.display} onChange={this.handleDisplayChange} required autofocus/>
          Password: <input class="form-control" type="password" value={this.state.password} onChange={this.handlePwChange} required />
          Password Again: <input class="form-control" type="password" value={this.state.password2} onChange={this.handlePw2Change} required />
          <button class="btn btn-lg btn-primary btn-block" type="submit">Create Account</button>
        </form>
      </div>
    );
  }
};

export default CreateAccount;
