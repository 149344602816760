import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, dark } from 'react-syntax-highlighter/dist/styles/hljs';

import './Note.css';

class Note extends Component {
  constructor(props) {
    super(props);
    this.deleteNote.bind(this)
  }

  deleteNote(){

  }

  displayDate(input) {
    var msec = Date.parse(input);
    var now = (new Date()).getTime();
    var diff = (now - msec).toString();

    var seconds = Math.ceil(diff/1000);

    if( diff < 1 )
      return "1 second ago";

    if( seconds < 60 )
      return seconds+" seconds ago";

    if( seconds < 119 )
      return "1 minute ago";

    var minutes = Math.floor(seconds/60);


    if( seconds < (60 * 60) ){
      return minutes+" minutes ago";
    }

    if( minutes < 120 )
      return "1 hour ago";

    var hours = Math.floor( seconds/(60 * 60) )

    if( seconds < (60 * 60 * 60)){
      return hours+" hours ago";
    }

    if( hours < 48 )
      return "1 day ago";

    var days = Math.floor( hours /24);
    if( hours > 24 ){
      return days+" days ago";
    }

    return diff;
  }

  render() {
    var tags = this.props.tags.map( (item,i) => {
      return (<div className={'singleTag '+item} key={i}>{item}</div>);
    });

    var fileLink = (<div className="nofile"/>);

    if( this.props &&
      this.props.file_id &&
      this.props.file_path &&
      this.props.file_path.length > 0 ){
      var url = "/api/file/"+this.props.file_id+"?auth="+this.props.auth;
      fileLink = (<a download={this.props.file_path} href={url}>{this.props.file_path}</a>);
    }else if ( this.props.href.length > 0 && this.props.title.length > 0 ) {
      fileLink = (<a href={this.props.href}>{this.props.title}</a>);
    }else{
      console.log(this.props);
    }

    return (
      <div className="singleNote col-sm-12">
        <div className="id">{this.props.id}</div>
        <SyntaxHighlighter
          language='text'
          wrapLines='true'
          useInlineStyles='true'
          showLineNumbers='true'
          style={dark}>
        {this.props.comment}
        </SyntaxHighlighter>
        <div className="created_at">{this.displayDate(this.props.created_at)}</div>
        <div className="row">
          <div className="tags col-sm-4">{tags}</div>
          <div className="col-sm-4 note_type">{this.props.category}</div>
          <div className="col-sm-4">{fileLink}</div>
        </div>
      </div>
    );
  }
};

export default Note;
